
import { Component, Vue } from "vue-property-decorator";
import Pagina from "@/components/Pagina/Pagina.vue";

@Component({
  components: {
    Pagina
  }
})
export default class GenericoView extends Vue {}
